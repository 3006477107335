import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import MainLayout from "../layout/MainLayout";

const PrivateRoute = () => {
  const user = useAuth();
  if (!user.token) return <Navigate to="/login" />;
  return MainLayout();
};

export default PrivateRoute;