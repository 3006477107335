import DocumentTitle from "react-document-title";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/AuthProvider";

function Profile() {
    const auth = useAuth()
    const [t] = useTranslation('common');

    return (
        <div className="flex min-h-full flex-col justify-center items-center px-6 py-12 lg:px-8">
            <DocumentTitle title={t("Welcome to", { app: process.env.REACT_APP_NAME })}></DocumentTitle>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    {t("Welcome user", { user: auth.user?.name })}
                </h2>
                <pre>
                    {JSON.stringify(auth.user, null, 2)}
                </pre>
            </div>
        </div>

    )
}

export default Profile;