import {ChatMessagesProps} from "../../types/ChatMessageType";

export default function ChatMessages({ messages }: ChatMessagesProps) {

    return (
        <>
            {!messages || !messages.length ? (
                <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg mb-5">
                    <div className={"text-center py-5"}>
                        No chat response yet.
                    </div>
                </div>
            ) : (
                messages.map((message, index) => {
                    return (
                        <div key={index} className="mb-7">
                            <div>
                                <div className=" px-3 py-2 sm:px-6 bg-indigo-50 ow-hidden shadow-sm sm:rounded-lg mb-2">
                                    <p className={""}><b>Prompt:</b> {message.prompt}</p>
                                </div>
                                <div className="px-3 py-2 sm:px-6 bg-gray-50 border border-dashed border-gray-400 overflow-hidden shadow-sm sm:rounded-lg mb-5">
                                    <p className={""}><b>Response:</b> {JSON.stringify(message.response)}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            )}
        </>

    );
}