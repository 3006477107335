import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider, { useAuth } from "./hooks/AuthProvider";
import MainLayout from "./layout/MainLayout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import axios from 'axios';
import PrivateRoute from "./components/PrivateRoute";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";

function App() {

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  const user: any = useAuth();

  axios.defaults.headers.common = {
    Authorization: user?.token ? `Bearer ${user.token}` : undefined,
    Accept: "application/json",
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
