import InputField from "../form-elements/InputField";
import React, {useState} from "react";
import * as yup from "yup";
import useYupValidationResolver from "../../hooks/YupValidationResolver";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {ChatMessage} from "../../types/ChatMessageType";

interface ChatFormProps {
    setMessages: React.Dispatch<React.SetStateAction<ChatMessage[] | null>>;
}

const ChatForm = ({ setMessages }: ChatFormProps) => {
    const [t] = useTranslation('common');
    const [error, setError] = useState<string | undefined>(undefined)

    const chatSchema = yup.object({
        prompt: yup.string().required('Prompt field is required'),
    })

    const resolver = useYupValidationResolver(chatSchema)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors , isSubmitting},
    } = useForm({ resolver })

    const onSubmit = async (data: any) => {
        try {
            setError(undefined)
            let result = await axios.post("/generate-response", {
                prompt: data.prompt
            }, {
                headers: {
                    "Accept": "application/json"
                }
            });
            reset();
            setMessages((prevMessages) => {
                if (prevMessages) {
                    return [...prevMessages, result.data.data]
                }
                return [result.data.data]
            });

        } catch (error: any) {
            console.log(error);
            setError(error.message)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className="mt-1 relative rounded-md shadow-sm flex">
                    <div className="w-full">
                        <InputField
                            type="text"
                            error={errors.prompt}
                            placeholder="Type your message here"
                            {...register('prompt')}
                        />
                    </div>
                    <button
                        type={"submit"}
                        disabled={isSubmitting}
                        className={`mt-2 w-auto h-auto justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 ${isSubmitting ? 'bg-gray-400' : 'bg-indigo-600'}`}
                    >
                        Send
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ChatForm;