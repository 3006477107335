import axios, { AxiosResponse } from "axios";
import { useContext, createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

type User = {
  id: number,
  name: string,
  email: string,
  roles: string[]
}

type AuthContextData = {
  token: string,
  user?: User,
  login: (username: string, password: string) => void,
  forgetPassword: (username: string) => void,
  verifyForgetPasswordOtp: (code: string) => Promise<any>,
  resetPassword: (resetToken: string, password: string) => void,
  logout: () => void
}

const AuthContext = createContext<AuthContextData>({
  token: "",
  user: {
    id: 0,
    name: "",
    email: "",
    roles: []
  },
  login: () => { },
  forgetPassword: () => { },
  verifyForgetPasswordOtp: async () => { },
  resetPassword: () => { },
  logout: () => { }
});

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(undefined);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();
  const [t] = useTranslation();
  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post("/login", {
        username, password
      });

      if (response.data) {
        setToken(response.data.data.token);
        localStorage.setItem("token", response.data.data.token);
        navigate("/");
        return;
      }
      throw new Error(response.data.message);
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };
  const forgetPassword = async (username: string) => {
    try {
      const response = await axios.post("/forget-password", {
        username,
      });
      if (response.data) {
        localStorage.setItem("forget-password-username", username)
        navigate("/reset-password");
        return;
      }
      throw new Error(response.data.message);
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };
  const verifyForgetPasswordOtp = (code: string) => {
    try {
      return axios.post("/forget-password/verify-otp", {
        username: localStorage.getItem("forget-password-username"),
        code
      });
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };
  const resetPassword = async (resetToken: string, password: string) => {
    try {
      const response = await axios.post("/reset-password", {
        "reset_token": resetToken,
        password
      });
      if (response.data) {
        localStorage.removeItem("forget-password-username");
        Swal.fire({
          title: t('Success'),
          html: t('Password reset successfully.') + "<br>" + t("Now you can try to login usign your new password."),
          icon: "success"
        });
        navigate("/login");
        return;
      }
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };
  const fetchMeData = () => {
    if (token) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      }
      axios.get("/me").then((response: AxiosResponse) => {
        setUser(response.data)
      }).catch((reason) => {
        console.log(reason);
        if (reason.response.status === 401) {
          localStorage.removeItem("token");
        }
      })
    } else {
      setUser(undefined)
    }
  }
  useEffect(fetchMeData, [token])

  const logout = () => {
    setUser(undefined);
    setToken("");
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{
      token,
      user,
      login,
      forgetPassword,
      verifyForgetPasswordOtp,
      resetPassword,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};