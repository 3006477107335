import * as yup from 'yup'
import useYupValidationResolver from '../hooks/YupValidationResolver'
import { useForm } from 'react-hook-form'
import InputField from '../components/form-elements/InputField'
import { useAuth } from '../hooks/AuthProvider'
import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import Swal from 'sweetalert2'

export default function ResetPassword() {

    const [resetToken, setResetToken] = useState<string>("");
    const [error, setError] = useState<string | undefined>(undefined)
    const auth = useAuth();

    const inputSchema = {
        code: yup.string().required('Code is required'),
        password: yup
            .string()
            .min(6, 'Password must have at least 6 characters')
            .required('Password is required'),
        password_again: yup
            .string()
            .required('Password Again is required')
            .oneOf([
                yup.ref('password')
            ], 'Passwords must match')
    };

    const verifyTokenSchema = yup.object({
        code: inputSchema.code
    })

    const resetPasswordSchema = yup.object({
        password: inputSchema.password,
        password_again: inputSchema.password_again
    })

    const verifyTokenResolver = useYupValidationResolver(verifyTokenSchema)
    const resetPasswordResolver = useYupValidationResolver(resetPasswordSchema)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: resetToken ? resetPasswordResolver : verifyTokenResolver })

    const onSubmit = async (data: any) => {
        try {
            setError(undefined)
            if (!resetToken) {
                auth.verifyForgetPasswordOtp(
                    data.code
                ).then((response) => {
                    reset()
                    setResetToken(response.data.reset_token)
                }).catch((error) => {
                    setError(error.response.data.message)
                })
            } else {
                auth.resetPassword(
                    resetToken,
                    data.password
                );
            }
        } catch (error: any) {
            setError(error.message)
        }
    }

    const resendMail = async () => {
        try {
            setError(undefined)
            await auth.forgetPassword(
                localStorage.getItem("forget-password-username") as string
            );
            Swal.fire({
                title: t('Success'),
                text: t('Email resent successfully.'),
                icon: "success"
            });
        } catch (error: any) {
            setError(error.message)
        }
    }

    const [t] = useTranslation('common');
    return <div className="flex min-h-full flex-col justify-center items-center px-6 py-12 lg:px-8">
        <DocumentTitle title={t('Reset Password')}></DocumentTitle>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                {t("Reset Password")}
            </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6">
                {
                    error ?
                        <div className='flex items-center p-4 mb-4 text-sm border-t-4 text-red-800 dark:text-red-400 bg-red-100 border-red-600'>
                            {error}
                        </div> :
                        (
                            resetToken ?
                                <></> :
                                <div className='flex items-center p-4 mb-4 text-sm border-t-4 text-green-800 dark:text-green-500 bg-green-100 border-green-600'>
                                    {t("If your email is registered in our system, you’ll receive an email in your inbox.")}
                                </div>
                        )}
                {
                    resetToken ?
                        <>
                            <InputField
                                label={t("Password")}
                                type="password"
                                autoComplete={'new-password'}
                                error={errors.password}
                                {...register('password')}
                            />
                            <InputField
                                label={t("Password Again")}
                                type="password"
                                autoComplete={'new-password'}
                                error={errors.password_again}
                                {...register('password_again')}
                            />
                            <input
                                type="submit"
                                value={t("Reset Password")}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            />
                        </> :
                        <>
                            <InputField
                                label={t("OTP code")}
                                type="number"
                                autoComplete={'off'}
                                error={errors.code}
                                {...register('code')}
                            />

                            <input
                                type="submit"
                                value={"Verify Code"}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            />
                        </>
                }

                {
                    resetToken ?
                        <></> :
                        <input
                            type="button"
                            value={t("Resend Mail")}
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={resendMail}
                        />
                }
                <Link to={"/login"} type='submit' className='mt-3 block text-sm font-semibold text-center underline-offset-1 cursor-pointer w-fit mx-auto'>
                    {t("Login")}
                </Link>
            </form>
        </div>
    </div>

}