import { Outlet } from "react-router-dom";
import { Navbar } from "../components/main-layout/Navbar";


const MainLayout = () => {
    return (
        <section className="bg-gray-200 min-h-[100vh]">
            <Navbar />
            <Outlet />
        </section >
    )
};

export default MainLayout;