import * as yup from 'yup'
import useYupValidationResolver from '../hooks/YupValidationResolver'
import { useForm } from 'react-hook-form'
import InputField from '../components/form-elements/InputField'
import { useAuth } from '../hooks/AuthProvider'
import { useState } from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DocumentTitle from 'react-document-title'

export default function ForgetPassword() {


    const [error, setError] = useState<string | undefined>(undefined)
    const auth = useAuth();

    const inputSchema = {
        email: yup.string().email('Email must be a valid email').required('Email is required'),
    }

    const forgotPasswordSchema = yup.object({
        email: inputSchema.email,
    })

    const resolver = useYupValidationResolver(forgotPasswordSchema)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver })

    const onSubmit = async (data: any) => {
        try {
            setError(undefined)
            await auth.forgetPassword(data.email);
        } catch (error: any) {
            setError(error.message)
        }
    }
    const [t] = useTranslation('common');
    return <div className="flex min-h-full flex-col justify-center items-center px-6 py-12 lg:px-8">
        <DocumentTitle title={t('Forgot Password')}></DocumentTitle>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                {t("Forgot Password")}
            </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6">
                {error ? <div className='flex items-center p-4 mb-4 text-sm border-t-4 text-red-800 dark:text-red-400 bg-red-100 border-red-600'>
                    {error}
                </div> : <></>}
                <InputField
                    label={t("Email Address")}
                    type="email"
                    autoComplete={'current-email'}
                    error={errors.email}
                    {...register('email')}
                />


                <input
                    type="submit"
                    value={t("Reset Password")}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                />

                <Link to={"/login"} type='submit' className='mt-3 block text-sm font-semibold text-center underline-offset-1 cursor-pointer w-fit mx-auto'>
                    {t("Login")}
                </Link>
            </form>
        </div>
    </div>

}