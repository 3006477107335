import DocumentTitle from "react-document-title";
import { useTranslation } from "react-i18next";
import ChatForm from "../components/forms/ChatForm";
import ChatMessages from "../components/others/ChatMessages";
import { useState } from "react";
import {ChatMessage} from "../types/ChatMessageType";

function Home() {
    const [t] = useTranslation('common');
    const [messages, setMessages] = useState<ChatMessage[] | null>(null);

    return (
        <div className="flex min-h-full flex-col justify-center items-center px-6 py-12 lg:px-8">
            <DocumentTitle title={t("Welcome to", { app: process.env.REACT_APP_NAME })}></DocumentTitle>
            <div className="container sm:mx-auto">
                <ChatMessages messages={messages} />
                <ChatForm setMessages={setMessages} />
            </div>
        </div>
    )
}

export default Home;