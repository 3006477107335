import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/AuthProvider";
import { Link } from "react-router-dom";

export const Navbar = () => {
    const auth = useAuth()
    const user = auth.user
    const [t] = useTranslation('common');

    return <nav className="bg-white border-gray-200 dark:bg-gray-500">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                <img src="/assets/logo.png" className="h-8" alt="Flowbite Logo" />
                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">{process.env.REACT_APP_NAME}</span>
            </Link>
            <div className="w-full md:w-auto text-white">
                <ul className="flex gap-3">
                    {auth.token ? (
                        <>
                            <li>
                                <button className="" onClick={auth.logout}>
                                    {t("Logout")}
                                </button>
                            </li>
                            <li>
                                <Link to={"/profile"}>
                                    {user?.name}
                                </Link>
                            </li>
                        </>
                    ) : (

                        <li>
                            <Link className="" to="/login">
                                <button>{t("Login")}</button>
                            </Link>
                        </li>

                    )}
                </ul>
            </div>
        </div>
    </nav>
}